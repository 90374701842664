@import "../../base.scss";

.article-text {
    display: inline-block;
    width: 95%;
    font-size: 20px;
    margin: 10px;
    font-family: $font-family-regular;
    &.italic {
        font-family: $font-family-italic;
    }
    &.bold-italic {
        font-family: $font-family-BoldItalic;
    }
}

.article-container {
    width: 100vw;
    display: flex;
    flex-flow: row;
    background-color: #081429;
    color: white;
}

.mobile-article-container,
.article-container {
    .text-container {
        all: unset;
        margin-left: 3%;
    }
}

.arrow-left {
    margin-left: 3%;
    background-image: url("../../assets/icons/tab-arrow.svg");
    transform: rotate(90deg);
    background-size: contain;
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    cursor: pointer;
    &.mobile {
        transform: rotate(90deg);
    }
}

.left-article-container {
    height: 100vh;
    padding: 10px;
    width: 50%;
    display: flex;
    flex-flow: column;
    .neshto {
        overflow-y: auto;
        height: 90%;
        margin-left: 2%;
        width: 95%;
    }

    ::-webkit-scrollbar {
        width: 3px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        -webkit-border-radius: 4px;
        border-radius: 4px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background-clip: padding-box;
        -webkit-border-radius: 10px;
        border-radius: 10px;
        background: #00d688;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    }
    ::-webkit-scrollbar-thumb:window-inactive {
        background: #00d688;
    }
}
.right-article-container {
    background-repeat: no-repeat;
    background-size: cover;
    width: 50%;
    height: 100vh;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.mobile-article-container {
    width: 100vw;
    h3 {
        padding: 5px;
        font-family: $font-family-Xbold;
    }
}

.mobile-article-text {
    padding: 10px;
    width: 90%;
    font-size: 14px;
    &.italic {
        font-family: $font-family-italic;
    }
    &.bold-italic {
        font-family: $font-family-BoldItalic;
    }
}

.mobile-right-article-container {
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 50vh;
}

.mobile-arrow-left {
    margin-top: 3%;
    margin-left: 2%;
    background-image: url("../../assets/icons/tab-arrow.svg");
    background-size: contain;
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    cursor: pointer;
}

.slider {
    width: 17%;
    height: 50px;
    margin-bottom: 3%;
    border-radius: 7px;
    border: 1px solid white;
    background-color: #1f1d2b;
    display: flex;
    align-items: center;
}
.slider-counter {
    padding: 1vw;
    background-color: white;
    color: black;
    height: 7%;
    display: flex;
    align-items: center;
    border-radius: 7px;
    font-size: 15px;
    width: 50%;
    justify-content: center;
    font-family: $font-family-light;
    b {
        font-family: $font-family-bold;
        font-size: 16px;
    }
}
.buttonn {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 40%;
    height: 25px;
    opacity: 1;
    cursor: pointer;
    &.inactive {
        pointer-events: none;
        opacity: 0.4;
    }
    &.backwards {
        background-image: url("../../assets/icons/arrow-left.svg");
    }
    &.forwards {
        background-image: url("../../assets/icons/arrow-left.svg");
        transform: rotate(180deg);
    }
}

@media screen and (max-width: 1600px) {
    .article-text {
        font-size: 18px;
    }
}
@media screen and (max-width: 1200px) {
    .article-text {
        font-size: 16px;
    }
}

@media screen and (max-width: 900px) {
    .slider {
        width: 99%;
        margin-top: 8%;
        margin-bottom: 3%;
    }
    .slider-counter {
        height: 60%;
        width: 25%;
    }
    .buttonn {
        height: 27px;
        &.forwards {
            margin-right: 2%;
        }
        &.backwards {
            margin-left: 2%;
        }
    }
}
