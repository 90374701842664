@import "fonts";

// Fonts
$font-family-bold: "Montserrat-Bold";
$font-family-Semibold: "Montserrat-SemiBold";
$font-family-medium: "Montserrat-Medium";
$font-family-regular: "Montserrat-Regular";
$font-family-black: "Montserrat-Black";
$font-family-light: "Montserrat-Light";
$font-family-thin: "Montserrat-Thin";
$font-family-Xbold:"Montserrat-ExtraBold";
$font-family-italic: "Montserrat-Italic";
$font-family-BoldItalic: "Montserrat-BoldItalic";

// Font-sizes
$fontSizeTitle: 60px;
$fontSizeSubtitle: 30px;
$fontSizeSubtitleSmall: 26px;
$fontSizeText: 22px;
$fontSizeTextSmall: 20px;
$fontSizeTextMobile: 16px;

// Colors
$white: #ffffff;
$black: #04010f;
$blue: #0046f8;
$lblue: #e6edff;
$border: #304a7d7f;
$linear-gradient: linear-gradient(#3ab2b6, #00d297);
$lgreen:#00D688;
$dblue:#081429;


%flex {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
}
