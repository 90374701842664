@import "./base.scss";


body {
  background-color: #081429;
  color: $white;
  font-size: $fontSizeText;
  font-family: $font-family-regular;
  overflow-x: hidden;
}
h1 {
  margin: 0;
  line-height: 1.5em;
}
h2{
  font-family: $font-family-regular;
}
p {
  font-size: 18px;
  font-family:$font-family-regular;
  line-height: 1.5em;
}

#subtitle {
  font-size: 32px;
  font-family: $font-family-medium;
}

.flex-container {
  @extend %flex;
}

b{
  font-family: $font-family-bold;
}
u{
  white-space: nowrap;
}

@media screen and (max-width: 1600px) {
  p {
    font-size: 18px;
  }
  h6 {
    font-size:18px;
  }
}

@media screen and (max-width: 1200px) {
  p {
    font-size: 14px;
  }
  h6 {
    font-size:16px;
  }
}

@media screen and (max-width: 900px) {
  body{
    overflow-x: hidden;
  }
  p {
    font-size: 16px;
    padding: 0 10px;
    margin-left:2%;
  }
  h6 {
    font-size:18px;
  }

  #upper-title{
    padding:0 5px;
    font-size:25px;
  }

  #lower-title{
    font-size:20px;
  }
 h4{
   margin-left:4%;
   margin-bottom: 5%;
 }
}

@media screen and (max-width: 400px) {
  
  p {
    font-size: 13px;
    padding: 0 10px;
  }

}

