*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  outline: none;
}
#root {
  min-height: 100vh;
}

body {
  margin: 0;
}

@import url("https://fonts.googleapis.com/css?family=Permanent+Marker");
@import url("https://fonts.googleapis.com/css?family=PT+Sans");

.dashboard-wrap{
  height: 100vh;
  width:  100vw;
}